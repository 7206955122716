import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// npm i element-ui -S
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入全局样式
import '@/assets/style.css'
// npm i axios
import request from "@/utils/request";
//npm install echarts
// main.js全局注册 npm install mavon-editor --s
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.request = request
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
